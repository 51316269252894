<template>
	<v-container class="py-8">
		<div class="mb-4 action-summary-area" :class="[
			$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : '',
			this.completeBatchProcess ? 'summary-visible' : '',
		]">
			<div v-if="this.completeBatchProcess || rejectedDocCount > 0"
				class="mb-2 final-doc-count font-weight-bold py-3 px-4">
				<div v-if="this.completeBatchProcess">
					{{ $t("document_list.signed_documents") }}:
					<span class="ml-1">{{ successDocs }}</span>
				</div>
				<div v-if="rejectedDocCount > 0">
					{{ $t("document_list.rejected_documents") }}:
					<span class="ml-1">{{ rejectedDocCount }}</span>
				</div>
			</div>

			<div class="mb-4 pending-doc-count font-weight-bold py-3 px-4">
				<div>
					{{ $t("document_list.documents_pending_signature") }}:
					<span class="ml-1">{{ numReqPending }}</span>
				</div>
				<div v-if="this.docList.length > 0">
					{{ $t("document_list.documents_selected_for_multiple_signature") }}:
					<span class="ml-1">{{ selectedDocs.length }}</span>
				</div>
			</div>
		</div>
		<v-card outlined class="document-list-card-wrapper" v-if="docList.length > 0">
			<v-data-table v-model="selectedDocs" class="document-list" :headers="headers" :items="docList" item-key="id"
				show-select :hide-default-footer="false" :mobile-breakpoint="0" item-class="status" :items-per-page="10"
				@toggle-select-all="checkSelectAll" :footer-props="{
			'items-per-page-options': [10],
		}">
				<template v-slot:[`item.signatureTransaction.description`]="{ item }">
					<router-link
						:to="`/${hasAssociation ? (currentRoute.params.association + '/') : ''}multiple-document/` + item.id"
						class="document-link">
						{{
			item.signatureTransaction.description
				? item.signatureTransaction.description
				: "-"
		}}
					</router-link>
				</template>
				<template v-slot:[`item.dateSent`]="{ item }">
					{{
			item.dateSent && item.dateSent !== null && item.dateSent != "null"
				? moment(item.dateSent).format("YYYY-MM-DD")
				: "-"
		}}
				</template>
				<template v-slot:[`item.signatureTransaction.validityDate`]="{ item }">
					{{
			item.signatureTransaction.validityDate &&
				item.signatureTransaction.validityDate != null &&
				item.signatureTransaction.validityDate != "null"
				? moment(item.signatureTransaction.validityDate).format(
					"YYYY-MM-DD"
				)
				: "-"
		}}
				</template>
			</v-data-table>
		</v-card>

		<v-card outlined class="document-list-card-wrapper" v-if="docList.length == 0">
			<v-data-table v-model="selectedDocs" class="document-list" :headers="headers" :items="docList" item-key="id"
				show-select :hide-default-footer="false" :mobile-breakpoint="0" item-class="status" :items-per-page="10"
				@toggle-select-all="checkSelectAll" :footer-props="{
			'items-per-page-options': [10],
		}">
				<template v-slot:[`item.signatureTransaction.description`]="{ item }">
					<router-link
						:to="`/${hasAssociation ? (currentRoute.params.association + '/') : ''}multiple-document/` + item.id"
						class="document-link">
						{{
			item.signatureTransaction.description
				? item.signatureTransaction.description
				: "-"
		}}
					</router-link>
				</template>
				<template v-slot:[`item.dateSent`]="{ item }">
					{{
			item.dateSent && item.dateSent !== null && item.dateSent != "null"
				? moment(item.dateSent).format("YYYY-MM-DD")
				: "-"
		}}
				</template>
				<template v-slot:[`item.signatureTransaction.validityDate`]="{ item }">
					{{
			item.signatureTransaction.validityDate &&
				item.signatureTransaction.validityDate != null &&
				item.signatureTransaction.validityDate != "null"
				? moment(item.signatureTransaction.validityDate).format(
					"YYYY-MM-DD"
				)
				: "-"
		}}
				</template>
			</v-data-table>
		</v-card>

		<div class="text-center pt-4 document-list-pagination-wrapper"></div>

		<div class="footer-message" v-if="docList.length > 0">
			<v-container class="px-2 fill-height">
				<v-row>
					<div class="col-md-8 col-xs-12 text-left terms">
						<v-list>
							<v-list-item>
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox dark color="#F36E21" v-model="terms_accepted" :input-value="active"
											:disabled="selectedDocs.length < 1"></v-checkbox>
									</v-list-item-action>

									<v-list-item-content class="white--text">
										{{ $t("document_list.read_and_understand_the_documents") }}
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list>
					</div>
					<div class="row">
						<div class="col-md-6 col-6 sign pl-0">
							<v-btn large color="#47D764" :disabled="!this.terms_accepted" block dark class="sign-reject-btn"
								:loading="loading" @click="signDocument()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-6 col-6 reject pr-0">
							<v-btn large color="#f36e21" class="sign-reject-btn w-100" block dark @click="review()"
								:disabled="docList.length < 1">
								{{ $t("document_list.review_and_select") }}
							</v-btn>
						</div>
					</div>
				</v-row>
			</v-container>
		</div>

		<doc-list-advance-search :dialog="advanceSearchDialog" :errorMsg="errorMsg" :error="error"
			@submitFilters="submitFilters"></doc-list-advance-search>

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error"
			@submitSignPad="submitSignPad"></sign-confirm-pad-modal>

		<certificate-pin-modal :dialog="certificatePinCodeDialog" :errorMsg="errorMsg" :error="error"
			@submitCertificatePin="submitCertificatePin"></certificate-pin-modal>

		<DefaultLoading v-if="loading" :loading="loading" />
	</v-container>
</template>

<script>
import DocListAdvanceSearch from "./shared/DocListAdvanceSearch.component";
import SignConfirmPadModal from "./shared/SignConfirmPadModal.component";
import CertificatePinModal from "./shared/CertificatePinModal.component.vue";
import DefaultLoading from "../shared/DefaultLoading.component";
import { SignaturesService } from "../../services/multiple/signatures-service";
import moment from "moment";

export default {
	name: "document-list",
	components: {
		DocListAdvanceSearch,
		SignConfirmPadModal,
		CertificatePinModal,
		DefaultLoading,
	},
	data: function () {
		return {
			loading: false,
			padDialog: false,
			certificatePinCodeDialog: false,
			certificatePinCode: null,
			signaturePadImage: null,
			errorMsg: null,
			error: null,
			terms_accepted: "",
			searchFilters: {},
			selectedDocs: [],
			advanceSearchDialog: false,
			totalSelectedDocs: 0,
			successDocs: 0,
			failDocs: 0,
			invalidPin: false,
			page: 1,
			totalPages: 0,
			itemsPerPage: 10,
			docList: [],
			pageNumber: 1,
			numReqPending: 0,
			searchKeyWord: null,
			completeBatchProcess: false,
			prevRoute: "-",

			pendingStatus: 20,
			searchFilterCount: 0,
			padStatus: false,
			rejectedDocCount: 0,
			searchUrlParams: {},
			authenticationCode: "",
			authToken: null,
			completedDocList: []
		};
	},
	computed: {
		headers() {
			return [
				{
					text: this.$t("document_list.document"),
					value: "signatureTransaction.description",
					sortable: false,
				},
				{
					text: this.$t("document_list.send"),
					value: "dateSent",
					sortable: false,
				},
				{
					text: this.$t("document_list.expiration"),
					value: "signatureTransaction.validityDate",
				},
			];
		},
		prevRoutePath() {
			return this.prevRoute ? this.prevRoute.path : "/";
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		currentRoute() {
			return this.$route;
		}
	},
	watch: {
		successDocs() {
			if (this.successDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
		failDocs() {
			if (this.failDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
	},
	methods: {
		resetProcess() {
			this.padDialog = false;
			this.certificatePinCodeDialog = false;
			this.certificatePinCode = null;
			this.signaturePadImage = null;
			this.errorMsg = null;
			this.error = null;
			this.terms_accepted = "";
			this.searchFilters = {};
			this.selectedDocs = [];
			this.advanceSearchDialog = false;
			this.totalSelectedDocs = 0;
			this.invalidPin = false;
			this.docList = [];
			this.totalPages = 0;
			this.pageNumber = 1;
			this.numReqPending = 0;

			this.searchKeyWord = "";
			this.padStatus = false;
			localStorage.setItem("reviewed_list", "");
			this.completedDocList = [];
		},
		checkBatchProcessStatus() {
			if (
				this.totalSelectedDocs == this.successDocs + this.failDocs &&
				!this.invalidPin
			) {
				this.page = 1;
				this.resetProcess();
				this.loadDocumentList({});
				this.certificatePinCodeDialog = false;
				this.completeBatchProcess = true;
				this.resetSuccessCount();
			}
		},
		showAdvanceSearchDialog() {
			this.advanceSearchDialog = true;
		},
		submitFilters(filters) {
			this.searchKeyWord = "";
			this.docList = [];
			this.searchFilters = JSON.parse(JSON.stringify(filters));
			this.page = 1;
			this.loadDocumentList(JSON.parse(JSON.stringify(filters)));
			this.advanceSearchDialog = false;
			localStorage.setItem("reviewed_list", "");
		},
		signDocument() {
			this.padStatus = true;
			this.padDialog = true;
			if (this.selectedDocs.find((o) => o.pad === true)) {
				this.padStatus = true;
				this.padDialog = true;
			} else {
				this.certificatePinCodeDialog = true;
				this.padStatus = false;
			}
		},
		submitSignPad(signature) {
			this.signaturePadImage = signature;
			this.padDialog = false;
			// this.certificatePinCodeDialog = true;
			this.submitBatchOfDocuments();
		},
		submitCertificatePin(pinCode) {
			this.certificatePinCode = pinCode;
			this.submitBatchOfDocuments();
		},
		async submitBatchOfDocuments() {
			console.log('submitting signature');
			if (this.selectedDocs.length > 0) {
				this.totalSelectedDocs = this.selectedDocs.length;
				this.invalidPin = false;
				this.error = false;
				this.loading = true;
				this.successDocs = 0;
				this.failDocs = 0;
				// console.log(this.selectedDocs);
				//  await this.signSingleDocument(apiToken, this.selectedDocs[0]);
				// // if (!this.invalidPin) {
				// this.selectedDocs.shift();
				(async () => {
					for (const list of this.selectedDocs) {
						await this.signSingleDocument(list.apiToken, list);
					}
				})();

			}

		},

		async signSingleDocument(apiToken, list) {
			let data = {
				comments: "signer commentaries", // added a default comment for testing
				authId: this.authenticationCode,
				location: "41.823709,35.892121",
				lastRefresh: "2022-06-17T08:26:22+0100",
				otp: "AL3UQM",
				signatureImage: this.signaturePadImage // make sure this is a base64 encoded image string
			};
			let signatureId = list.id;

			await SignaturesService.signCertificateSignaturesDocument(
				apiToken,
				data,
				signatureId
			)
				.then((response) => {
					if (response.status == 200) {
						this.successDocs++;
					}
				})
				.catch((error) => {
					console.log(error);
					this.failDocs++;
					this.error = true;
					this.loading = false;
					if (error.response.status == 422) {
						this.invalidPin = true;
						this.error = true;
						this.errorMsg =
							error.response.data.description == "SYSTEM.PKCS11_PIN_INCORRECT"
								? this.$t("document_list.invalid_pin_code")
								: this.$t("server_error");
						this.loading = false;
					}

				});
		},

		async signCertificateSignature(apiToken, list) {
			let data = { comments: "", password: this.certificatePinCode };
			if (list.pad) {
				data["signatureImage"] = this.signaturePadImage;
			}
			let signatureId = list.id;
			await SignaturesService.signCertificateSignaturesDocument(
				apiToken,
				data,
				signatureId
			)
				.then((response) => {
					if (response.status == 200) {
						this.successDocs++;
					}
				})
				.catch((error) => {
					this.failDocs++;
					if (error.response.status == 422) {
						this.invalidPin = true;
						this.error = true;
						this.errorMsg =
							error.response.data.description == "SYSTEM.PKCS11_PIN_INCORRECT"
								? this.$t("document_list.invalid_pin_code")
								: this.$t("server_error");
						this.loading = false;
					}
				});
		},
		review() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}multiple-document-list-review` })
		},
		setParams(params) {

			this.searchFilterCount = Object.keys(this.searchFilters).filter(
				(x) => this.searchFilters[x] != undefined
			).length;
			if (this.searchFilterCount > 0) {
				Array.prototype.push.apply(params, this.searchFilters);
			}
			if (this.searchKeyWord) {
				params["description"] = this.searchKeyWord;
			}
			if ( //Is redirect from another page
				this.prevRoute.name &&
				this.prevRoute.name == "document-list-review"
			) {
				this.page = 1;
				this.prevRoute = "";
			}
			params["page"] = this.page;
			params["status"] = this.pendingStatus;
			return params;
		},
		resetRejectCount() {
			setTimeout(
				function () {
					this.rejectedDocCount = 0;
					localStorage.setItem("rejected_doc_count", this.rejectedDocCount);
				}.bind(this),
				4000
			);
		},
		resetSuccessCount() {
			setTimeout(
				function () {
					this.completeBatchProcess = false;
					this.successDocs = 0;
					this.failDocs = 0;
				}.bind(this),
				4000
			);
		},
		loadDocumentList(paramsList) {
			this.loading = true;
			let params = this.setParams(paramsList);
			const apiToken = this.authToken;
			const authCode = this.authenticationCode;

			SignaturesService.getCertificateSignatureRequests(apiToken, params, authCode)
				.then((response) => {
					this.docList = this.docList.concat(
						response.data.signatureRequestsList
					);
					if (this.docList.length == 0) {
						console.log('empty odc');
						this.loadCompletedDocList(paramsList);
					}
					this.setReviewLocalData(params);
					this.page = response.data.pageNumber;
					this.totalPages = response.data.totalPages;
					this.numReqPending = response.data.numReqPending;

					this.loading = false;
					this.loadAllPagination();
					this.checkSelectAll({ value: true });
				})
				.catch(() => {
					this.loading = false;
				});
		},

		loadCompletedDocList(paramsList) {

			this.loading = true;
			let params = this.setParams(paramsList);
			const apiToken = this.authToken;
			const authCode = this.authenticationCode;

			SignaturesService.getCompletedCertificateSignatureRequestList(apiToken, params, authCode)
				.then((response) => {
					console.log(response);
					this.completedDocList = this.completedDocList.concat(
						response.data.signatureRequestsList
					);
					this.page = response.data.pageNumber;
					this.totalPages = response.data.totalPages;
					this.numReqPending = response.data.numReqPending;

					this.loading = false;
					this.loadAllPagination();
				})
				.catch(() => {
					this.loading = false;
				});
		},
		loadAllPagination() {
			if (this.totalPages > this.page) {
				const reviewParams = this.getReviewUrlParams();
				this.page = this.page + 1;
				this.loadDocumentList(reviewParams);
			} else {
				const reviewedList = this.checkReviewedDocumentList();
				if (reviewedList) {
					this.selectedDocs = reviewedList;
				} else {
					this.selectedDocs = this.docList;
				}
				this.rejectedDocCount = Number(this.getRejectedCount());
				this.resetRejectCount();
			}
		},
		getReviewUrlParams() {
			return JSON.parse(JSON.stringify(this.searchUrlParams));
		},
		checkReviewedDocumentList() {
			const reviewed =
				localStorage.getItem("reviewed_list") != ""
					? JSON.parse(localStorage.getItem("reviewed_list"))
					: "";
			return reviewed.length > 0 ? reviewed : "";
		},
		setReviewLocalData(params) {
			this.searchUrlParams = params;
		},
		loadSearchList() {
			this.searchFilters = {};
			this.docList = [];
			this.page = 1;
			this.loadDocumentList({});
			localStorage.setItem("reviewed_list", "");
		},
		getRejectedCount() {
			return localStorage.getItem("rejected_doc_count");
		},
		setRejectedCount(value) {
			return localStorage.setItem("rejected_doc_count", value);
		},
		checkSelectAll(x) {
			console.log('checkSelectAll called with:', x);
			if (x.value) {
				this.$set(this, 'selectedDocs', this.docList.slice());
			} else {
				this.$set(this, 'selectedDocs', []);
			}
			console.log('Selected Docs after setting:', this.selectedDocs);
		}
	},
	mounted() {
		this.loadDocumentList({});
		this.moment = moment;
		this.checkSelectAll({ value: true });
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prevRoute = from;
		});
	},
	created() {
		this.authToken = localStorage.getItem("auth_user_token") || null;
		this.authenticationCode = localStorage.getItem('auth_id') || null;
	},
};
</script>
