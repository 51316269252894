<template>
  <v-overlay v-model="dialog" color="#0E82B9" opacity="0.97">
    <v-btn
      class="mx-2 close-filter"
      fab
      dark
      small
      color="primary"
      depressed
      @click="closeAdvanceSearchDialog()"
    >
      <v-icon dark>
        mdi-close
      </v-icon>
    </v-btn>

    <v-container class="document-list-filter-wrapper">
      <v-row class="pa-5 text-center">
        <div class="col-12 white--text mb-6">
          <h3>{{ $t("document_filter.filter_by") }}</h3>
        </div>

        <div class="col-12 mb-4 px-2">
          <input
            type="text"
            id="custom-input"
            :placeholder="$t('document_filter.title')"
            v-model="title"
          />
        </div>

        <div class="col-sm-6 col-xs-12 mb-4 px-2">
          <v-menu
            ref="menuInitialIssueDate"
            v-model="menuInitialIssueDate"
            :return-value.sync="initialIssueDate"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="initialIssueDate"
                id="custom-input"
                :placeholder="$t('document_filter.initial_issue_date')"
                light
                append-outer-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="initialIssueDate"
              color="primary"
              no-title
              scrollable
              @change="$refs.menuInitialIssueDate.save(initialIssueDate)"
              :min="initialIssueMinDate"
              :max="initialIssueMaxDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuInitialIssueDate = false">
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="col-sm-6 col-xs-12 mb-4 px-2">
          <v-menu
            ref="menuFinalIssueDate"
            v-model="menuFinalIssueDate"
            :return-value.sync="finalIssueDate"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="finalIssueDate"
                id="custom-input"
                :placeholder="$t('document_filter.final_issue_date')"
                light
                append-outer-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="finalIssueDate"
              color="primary"
              no-title
              scrollable
              @change="$refs.menuFinalIssueDate.save(finalIssueDate)"
              :min="finalIssueMinDate"
              :max="finalIssueMaxDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuFinalIssueDate = false">
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="col-sm-6 col-xs-12 mb-4 px-2">
          <v-menu
            ref="menuInitialDueDate"
            v-model="menuInitialDueDate"
            :return-value.sync="initialDueDate"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="initialDueDate"
                id="custom-input"
                :placeholder="$t('document_filter.initial_due_date')"
                light
                append-outer-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="initialDueDate"
              color="primary"
              no-title
              scrollable
              @change="$refs.menuInitialDueDate.save(initialDueDate)"
              :min="initialDueMinDate"
              :max="initialDueMaxDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuInitialDueDate = false">
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="col-sm-6 col-xs-12 mb-4 px-2">
          <v-menu
            ref="menuFinalDueDate"
            v-model="menuFinalDueDate"
            :return-value.sync="finalDueDate"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="finalDueDate"
                id="custom-input"
                :placeholder="$t('document_filter.final_due_date')"
                light
                append-outer-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="finalDueDate"
              color="primary"
              no-title
              scrollable
              @change="$refs.menuFinalDueDate.save(finalDueDate)"
              :min="finalDueMinDate"
              :max="finalDueMaxDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuFinalDueDate = false">
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="mt-3 mx-auto">
          <v-btn depressed min-width="96" class="mr-5" @click="clearFilters">
            {{ $t("document_filter.clear_filter") }}
          </v-btn>
          <v-btn
            color="#F36E21"
            depressed
            min-width="96"
            @click="submitFilters"
          >
            {{ $t("document_filter.apply_filter") }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dialog: Boolean,
    error: Boolean,
    errorMsg: String,
  },
  data: () => ({
    title: null,
    initialIssueDate: null,
    finalIssueDate: null,
    initialDueDate: null,
    finalDueDate: null,
    menuInitialIssueDate: false,
    menuFinalIssueDate: false,
    menuInitialDueDate: false,
    menuFinalDueDate: false,
    initialIssueMinDate: "",
    initialIssueMaxDate: "",
    finalIssueMinDate: "",
    finalIssueMaxDate: "",
    initialDueMinDate: "",
    initialDueMaxDate: "",
    finalDueMinDate: "",
    finalDueMaxDate: "",
  }),
  watch: {
    initialIssueDate() {
      this.finalIssueMinDate = this.momentDateAdd(this.initialIssueDate);
    },
    finalIssueDate() {
      this.initialIssueMaxDate = this.momentDateSubtract(this.finalIssueDate);
    },
    initialDueDate() {
      this.finalDueMinDate = this.momentDateAdd(this.initialDueDate);
    },
    finalDueDate() {
      this.initialDueMaxDate = this.momentDateSubtract(this.finalDueDate);
    },
  },
  methods: {
    submitFilters() {
      this.$emit("submitFilters", {
        description: this.title,
        "ge-date": this.initialIssueDate,
        "le-date": this.finalIssueDate,
        "ge-validityDate": this.initialDueDate,
        "le-validityDate": this.finalDueDate,
      });
    },
    closeAdvanceSearchDialog() {
      this.clearFilters();
      this.$emit("submitFilters", {});
    },
    clearFilters() {
      this.title = null;
      this.initialIssueDate = null;
      this.finalIssueDate = null;
      this.initialDueDate = null;
      this.finalDueDate = null;
      this.resetMinMaxValues();
    },
    momentDateAdd(currentDate) {
      return currentDate
        ? this.moment(currentDate, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD")
        : null;
    },
    momentDateSubtract(currentDate) {
      return currentDate
        ? this.moment(currentDate, "YYYY-MM-DD")
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        : null;
    },
    resetMinMaxValues() {
      this.initialIssueMinDate = "";
      this.initialIssueMaxDate = "";
      this.finalIssueMinDate = "";
      this.finalIssueMaxDate = "";
      this.initialDueMinDate = "";
      this.initialDueMaxDate = "";
      this.finalDueMinDate = "";
      this.finalDueMaxDate = "";
    },
  },
  mounted() {
    this.moment = moment;
  },
};
</script>
