<template>
  <v-overlay v-model="dialog" color="#0E82B9" opacity="0.92">
    <v-container>
      <div class="model-main-loader">
        <DefaultSignLoading v-if="loading" :loading="loading" />
      </div>
      <v-row class="pa-2 text-center">
        <div>     
          <!-- Existing content -->
          <div v-if="error" class="col-sm-12 col-md-12">
            <v-alert class="mt-5 red" border="left" text>
              {{ errorMsg }}
            </v-alert>
          </div>
          <div class="col-12 white--text">
            <strong>{{ $t("and_we_are_almost_there") }}</strong>
            <p>{{ $t("draw_your_signature_here") }}</p>
          </div>
          <div class="signature-pad">
            <div class="col-sm-12 pb-5">
              <VueSignaturePad id="signature" width="100%" height="280px" ref="signaturePad" :options="options" />
            </div>
            <div class="row">
              <div class="col-4 text-left">
                <v-btn color="#817975" @click="undo">{{ $t("clean") }}</v-btn>
              </div>
              <div class="col-4 signature-above">
                {{ $t("signature_above") }}
              </div>
              <div class="col-4 text-right">
                <v-btn color="#F36E21" @click="confirm()">{{ $t("save") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
  </v-overlay>
</template>


<script>
import DefaultSignLoading from "./DefaultSignLoading.component";

export default {
  name: "sign-confirm-pad-modal",
  data: () => ({
    loading: false,
    options: {
      penColor: "#000000",
      padImage: "",
    },
  }),
  props: {
    dialog: Boolean,
    error: Boolean,
    errorMsg: String,
  },
  components: {
    DefaultSignLoading,
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    confirm() {
      this.loading = true;
      const { data } = this.$refs.signaturePad.saveSignature();
      this.padImage = data;
      this.$emit("submitSignPad", this.padImage);
      this.loading = false;
    },
    reset() {
      this.padImage = "";
    },
  },
};
</script>

<style>
#signature {
  border: 1px solid #cdbfbf;
  border-radius: 5px;
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.signature-pad {
  background: white;
  padding: 15px 15px;
  border-radius: 7px;
  width: 100%;
}

.signature-pad .signature-above {
  color: #cecece;
}
</style>
