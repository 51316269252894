<template>
  <v-overlay :value="dialog" color="#0E82B9" opacity="0.95">
    <v-container>
      <v-row class="pa-10 text-center">
        <div v-if="error" class="col-sm-1 col-md-3"></div>
        <div v-if="error" class="col-sm-10 col-md-6">
          <v-alert class="mt-5 red" border="left" text>
            {{ errorMsg }}
          </v-alert>
        </div>
        <div v-if="!error" class="col-12 white--text"><strong>{{$t('document_list.sms_code_label')}}</strong></div>
        <div v-if="!error" class="col-12 white--text">{{$t('document_list.sms_code_text')}}</div>
        <div class="col-md-12 col-sm-12">
          <input
            type="password"
            id="custom-input"
            :placeholder="
              $t('document_list.enter_here_the_pin_of_your_certificate')
            "
            v-model="pinCode"
          />
        </div>
        <div class="col-12">
          <v-btn
            color="#F36E21"
            :disabled="this.pinCode.length < 1"
            @click="confirm()"
            depressed
            class="mt-3"
            min-width="96"
          >
            {{ $t("ok") }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
export default {
  name: "certificate-pin-modal",
  props: {
    dialog: Boolean,
    error: Boolean,
    errorMsg: String,
  },
  data: function() {
    return {
      pinCode: "",
    };
  },
  watch: {
    dialog() {
      if (this.dialog && !this.error) {
        this.pinCode = "";
      }
    },
  },
  methods: {
    confirm() {
      this.$emit("submitCertificatePin", this.pinCode);
    },
  },
};
</script>
